import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './home.scss';

const Home = () => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (imageLoaded) {
                navigate('/societykey');
            }
        }, 6000); // Delay for 6 seconds after the image loads (adjust as needed)

        // Clear the timeout when the component unmounts to avoid memory leaks
        return () => clearTimeout(timer);
    }, [imageLoaded]);

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100vh',
    };

    const imageStyle: React.CSSProperties = {
    maxWidth: '65%',
    maxHeight: '65vh',
    width: 'auto',
    height: 'auto',
    marginTop: '20px', // Adjust the vertical position
    position: 'relative',
    top: '0', // Reset any positioning
};


    const linkStyle: React.CSSProperties = {
        color: '#73AEEA',
        fontSize: '1rem',
        textDecoration: 'none',
        position: 'relative',
        bottom: '0',
        marginTop: '0px',
        textAlign: 'center',
    };

    return (
        <div className='home-total' style={containerStyle}>
            <img
                className='home-logos'
                src='./img/spage3.PNG'
                alt='spage2'
                style={imageStyle}
                onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image has loaded
            />
            <div className='home-link-container'>
                <Link to='/societykey' className='home-link-button' style={linkStyle}>
                    O P E N
                    <br />
                    <br />
                </Link>
            </div>
        </div>
    );
};

export default Home;
