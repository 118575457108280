import { BigNumber } from '@ethersproject/bignumber'

export const BIG_ZERO = BigNumber.from('0')

export const SocietyCoinContract = '0x577dc2405Db2a1279ac0930aBecefF0EB3AbC191';
export const SocietyNobleContract = '0xE613F2168829b9Aab7DB71344372AD3c59331A26';
export const SocietyKeyContract = '0x3613a70252d4A10957eeF1bd4f86f5b734b0f1B0';
export const SocietyCoinGiftContract = '0x6aCef07a429C93412b4b78718d63B61c93f17e95';
export const SocietyNobleGiftContract = '0x2e7332114Bb8Bd1aD3C6B898546a8154C417bbD0';
export const NobleSwapContract = '0x873cF385197430195e73d8694FF2EA126d6a86Ff';

// pos
export const SocietyH2O = '0xa38867248b2d3a200aab2f34b575d140ecb713c7';
export const NeonTigerSociety = '0x15ebDdb7B2B89017db06fF6006D893D1b90E6A04';

// pos stack

export const LV1NobleStack = '0xe439eeab4dabbe3c87438071d6a95a3c7a3bccc2';
export const LV2NobleStack = '0xeb46f049100009f716c01384a232629a96368e3c';
export const LV3NobleStack = '0x680d71f4717e8ae0a7c833c1818caa8db1d1a4e7';
export const LV4NobleStack = '0x8bc2c67ac12b7d2e118d3203ed103ed434acc5f3';

// stack noble stack

export const StackLv1Noblestack = '0xf35178303c83E503039a266E3877354B4a14D90A';
export const StackLv2Noblestack = '0x1f2d2c16fc8fafac1cdcd87283b3bcd35e600cf9';
export const StackLv3Noblestack = '0x110Db5d7aF10cAd47826510674e8F6131A27d452';
export const StackLv4Noblestack = '0x6EA76906c700d058c4CF87408980A287d6A38618';

//p2p swap

export const P2pSwapLv1Noblestack = '0x85A58CE935Dd1cda7dA9bdfAb6ba621E86d6f4C8';
export const P2pSwapLv2Noblestack = '0xf059585c15160cd5df358d3bc8a6fe316c9dd7f3';
export const P2pSwapLv3Noblestack = '0xd712931b5ae53895a804f754584b0d62b0def02e';
export const P2pSwapLv4Noblestack = '0x66fa759158289bc1b5e35409d5f7a3e6fa868c03';

export const P2pSwapSocietyCoin = '0x171EF1560EAdF2B5F6E01F1Eb9a56ad62B606507';

// Staking
export const Staking_NTScty = "0xe1f9d3eeb20731dba200ecc59f8599ec794bd58b";
export const Staking_SctyH2O = "0x97cf87a708b32a2782921d2b903bd5bc644d3236";

export const UsdcAdress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";

export const InfuraId = '8a36e75be65141c6974722768ac3848d'
