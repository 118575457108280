import { Interface } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { useEthers } from '@usedapp/core'
import { BigNumber } from '@ethersproject/bignumber'

import SocietyNoble from '../global/SocietyNoble.json'
import SocietyCoin from '../global/SocietyCoin.json'
import SocietyCoinGift from '../global/SocietyCoinGift.json'
import SocietyNobleGift from '../global/SocietyNobleGift.json'
import SocietyKey from '../global/SocietyKey.json'
import NobleSwap from '../global/NobleSwap.json'

import {
    SocietyNobleContract,
    SocietyCoinContract,
    SocietyKeyContract,
    SocietyCoinGiftContract,
    SocietyNobleGiftContract,
    NobleSwapContract
} from '../global/constants'

export default function useEstimateGas() {
    const SocietyNobleABI = new Interface(SocietyNoble)
    const SocietyCoinABI = new Interface(SocietyCoin)
    const SocietyCoinGiftABI = new Interface(SocietyCoinGift)
    const SocietyNobleGiftABI = new Interface(SocietyNobleGift)
    const SocietyKeyABI = new Interface(SocietyKey)
    const NobleSwapABI = new Interface(NobleSwap)
    const { library } = useEthers()

    const claimSocietyNobleGiftGas = async (address: string | undefined) => {
        const contract = new Contract(
            SocietyNobleGiftContract,
            SocietyNobleGiftABI,
            library?.getSigner(),
        )
        //console.log("Contract",contract);
        const estimatedGas = await contract.estimateGas.claimRewards()

        return estimatedGas
    }

    const claimSocietyCoinGiftGas = async (address: string | undefined) => {
        const contract = new Contract(
            SocietyCoinGiftContract,
            SocietyCoinGiftABI,
            library?.getSigner(),
        )
        const estimatedGas = await contract.estimateGas.claimRewards()

        return estimatedGas
    }

    const claimSocietyKeyGas = async (address: string | undefined) => {
        const contract = new Contract(
            SocietyKeyContract,
            SocietyKeyABI,
            library?.getSigner(),
        )
        const estimatedGas = await contract.estimateGas.claim(address)

        return estimatedGas
    }

    const sendSocietyKeyGas = async (
        address: string | undefined,
        amount: BigNumber | undefined,
    ) => {
        const contract = new Contract(
            SocietyKeyContract,
            SocietyKeyABI,
            library?.getSigner(),
        )
        const estimatedGas = await contract.estimateGas.transfer(
            address,
            amount,
        )
        const gasPrice = await library?.getGasPrice()

        return { estimatedGas, gasPrice }
    }

    const sendSocietyCoinGas = async (
        address: string | undefined,
        amount: BigNumber | undefined,
    ) => {
        const contract = new Contract(
            SocietyCoinContract,
            SocietyCoinABI,
            library?.getSigner(),
        )
        const estimatedGas = await contract.estimateGas.transfer(
            address,
            amount,
        )
        const gasPrice = await library?.getGasPrice()

        return { estimatedGas, gasPrice }
    }

    const sendSocietyNobleGas = async (
        address: string | undefined,
        amount: BigNumber | undefined,
    ) => {
        const contract = new Contract(
            SocietyNobleContract,
            SocietyNobleABI,
            library?.getSigner(),
        )
        const estimatedGas = await contract.estimateGas.transfer(
            address,
            amount,
        )
        const gasPrice = await library?.getGasPrice()

        return { estimatedGas, gasPrice }
    }

    const unlockNobilityGas = async (
        address: string | undefined,
        levelVal: number | undefined,
    ) => {
        const contract = new Contract(
            NobleSwapContract,
            NobleSwapABI,
            library?.getSigner(),
        )
        const estimatedGas = await contract.estimateGas.accumlateNobles(
            levelVal
        )
        const gasPrice = await library?.getGasPrice()

        return { estimatedGas, gasPrice }
    }
    
    return {
        claimSocietyNobleGiftGas,
        claimSocietyCoinGiftGas,
        claimSocietyKeyGas,
        sendSocietyKeyGas,
        sendSocietyCoinGas,
        sendSocietyNobleGas,
        unlockNobilityGas
    }
}
